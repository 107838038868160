import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import web3 from './lib/web3';
import origami from './lib/origami';

import Creator from './routes/Creator';
import ProofQuery from './routes/ProofQuery';

import './App.scss';

export default () => (
  <Router>
    <Switch>
      <Route exact path="/create" component={(props) => <Creator {...props} web3={web3} origami={origami} capable={web3 && origami} />} />
      <Route path="/:proof" component={(props) => <ProofQuery {...props} web3={web3} origami={origami} capable={web3 && origami} />} />
    </Switch>
  </Router>
);
