export default [
	{},
	{
		0: "1",
		1: "08/30/2018",
		2: "Infosys",
		3: "0x09286B101ac2FA8216B76C7020344275C6aE7D8d",
		4: "{ \"designedAndManufacturedBy\": \"Kevin Dunnell & Christopher Paprocki\", \"ideaLocation\": \"Santiago\", \"testLocation\": \"Lima\", \"mfgLocation\": \"Medellín\", \"paperSource\": \"Fabriano\", \"boxSource\": \"Boston\", \"hoursCompressed\": 600, \"piecesPaper\": 16 }",
		5: "Mobile World Congress - Los Angeles",
		artifactOwner: "Infosys",
		details: "{ \"designedAndManufacturedBy\": \"Kevin Dunnell & Christopher Paprocki\", \"ideaLocation\": \"Santiago\", \"testLocation\": \"Lima\", \"mfgLocation\": \"Medellín\", \"paperSource\": \"Fabriano\", \"boxSource\": \"Boston\", \"hoursCompressed\": 1.2, \"piecesPaper\": 16 }",
		ownerAddress: "0x22C5eCF0c794D9DEF09Af18C86C4E92e8ADd5e5a",
		presentedAt: "Mobile World Congress - Los Angeles",
		proof: "1",
		timeAndDateCompleted: "08/30/2018",
		handMadeBy: "Kevin Dunnell"
	},
		{
		0: "1",
		1: "08/30/2018",
		2: "Infosys",
		3: "0x09286B101ac2FA8216B76C7020344275C6aE7D8d",
		4: "{ \"designedAndManufacturedBy\": \"Kevin Dunnell & Christopher Paprocki\", \"ideaLocation\": \"Santiago\", \"testLocation\": \"Lima\", \"mfgLocation\": \"Medellín\", \"paperSource\": \"Fabriano\", \"boxSource\": \"Boston\", \"hoursCompressed\": 600, \"piecesPaper\": 16 }",
		5: "Telstra Vantage - Melbourne",
		artifactOwner: "Infosys",
		details: "{ \"designedAndManufacturedBy\": \"Kevin Dunnell & Christopher Paprocki\", \"ideaLocation\": \"Santiago\", \"testLocation\": \"Lima\", \"mfgLocation\": \"Medellín\", \"paperSource\": \"Fabriano\", \"boxSource\": \"Boston\", \"hoursCompressed\": 1.2, \"piecesPaper\": 16 }",
		ownerAddress: "0x22C5eCF0c794D9DEF09Af18C86C4E92e8ADd5e5a",
		presentedAt: "Telstra Vantage - Melbourne",
		proof: "1",
		timeAndDateCompleted: "08/30/2018",
		handMadeBy: "Kevin Dunnell"
	}
];