import web3 from './web3';

// Ropsten Network
const address = '0x724355dfcf91db3f42c2110c5d0aee6201e62709';

// TODO: Kevin, this is the one with the coords that doesn't work
// const address = '0x32c4780926c2f0209810e3c00b5febe2d7ad314c';

const abi = [
    {
        "constant": false,
        "inputs": [
            {
                "name": "start",
                "type": "uint256"
            },
            {
                "name": "end",
                "type": "uint256"
            },
            {
                "name": "location",
                "type": "string"
            },
            {
                "name": "madeBy",
                "type": "string"
            }
        ],
        "name": "batchCreateArtifacts",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "proof",
                "type": "uint256"
            },
            {
                "name": "presentedAt",
                "type": "string"
            },
            {
                "name": "handMadeBy",
                "type": "string"
            }
        ],
        "name": "createArtifact",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "artifactProof",
                "type": "uint256"
            },
            {
                "name": "newOwner",
                "type": "string"
            },
            {
                "name": "newAddress",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "artifactCreatorAddress",
        "outputs": [
            {
                "name": "",
                "type": "address"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "artifacts",
        "outputs": [
            {
                "name": "proof",
                "type": "uint256"
            },
            {
                "name": "timeAndDateCompleted",
                "type": "uint256"
            },
            {
                "name": "artifactOwner",
                "type": "string"
            },
            {
                "name": "ownerAddress",
                "type": "address"
            },
            {
                "name": "details",
                "type": "string"
            },
            {
                "name": "presentedAt",
                "type": "string"
            },
            {
                "name": "handMadeBy",
                "type": "string"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }
];

const contract = web3 ? new web3.eth.contract(abi, address) : null;

// window to ethereum contract - local copy
export default contract;
