import React, { Component } from 'react';

class Creator extends Component {
  state = {
    artifactCreatorAddress: '',
    proofin:0,
    madeByin:'',
    proofstart:0,
    proofend:0,
    presentedAt: '',
    message: ''
  };


  async componentDidMount() {
    const artifactCreatorAddress = await this.props.origami.methods.artifactCreatorAddress().call();
    this.setState({ artifactCreatorAddress });
  }

  onCreate = async (event) => {
   event.preventDefault();

   const accounts = await this.props.web3.eth.getAccounts();

   this.setState({ message: 'Waiting on transaction success...'})
   
   await this.props.origami.methods.createArtifact(
    this.state.proofin,
    this.state.presentedAt,
    this.state.madeByin
    ).send({
    from: accounts[0]
    });

   this.setState({message: 'Artifact has been recorded!'});

  }

  onCreateBatch = async (event) => {
   event.preventDefault();

   const accounts = await this.props.web3.eth.getAccounts();

   this.setState({ message: 'Waiting on transaction success...'})
   
   await this.props.origami.methods.batchCreateArtifacts(
    this.state.proofstart,
    this.state.proofend,
    this.state.presentedAt,
    this.state.madeByin
    ).send({
    from: accounts[0]
    });

   this.setState({message: 'Multiple artifacts have been recorded!'});

  }

  render() {
    //console.log(this.props.web3.version);

    return (
      <div>
        <h2>Origami Artifacts</h2>
        <p>These artifacts and this contract were created by {this.state.artifactCreatorAddress}</p>
        
        <hr />

        <form onSubmit={this.onCreate}>
          <h4>Create New Artifact</h4>
            <div>
              <label>Proof In</label>
                <input 
                proofin={this.state.value}
                onChange={event => this.setState({ proofin: event.target.value })}
                />
            </div>
            <div>
              <label>Place In</label>
                <input 
                presentedat={this.state.value}
                onChange={event => this.setState({ presentedAt: event.target.value })}
                />
            </div>
            <div>
              <label>Made By In</label>
                <input 
                madebyin={this.state.value}
                onChange={event => this.setState({ madeByin: event.target.value })}
                />
            </div>
          <button>Enter</button>
        </form>

        <hr />
        <form onSubmit={this.onCreateBatch}>
          <h4>Create Multiple New Artifacts</h4>
            <div>
              <label>Proof Start</label>
                <input 
                proofstart={this.state.value}
                onChange={event => this.setState({ proofstart: event.target.value })}
                />
            </div>
            <div>
              <label>Proof End</label>
                <input 
                proofend={this.state.value}
                onChange={event => this.setState({ proofend: event.target.value })}
                />
            </div>
            <div>
              <label>Place In</label>
                <input 
                presentedat={this.state.value}
                onChange={event => this.setState({ presentedAt: event.target.value })}
                />
            </div>
            <div>
              <label>Made By In</label>
                <input 
                madebyin={this.state.value}
                onChange={event => this.setState({ madeByin: event.target.value })}
                />
            </div>
          <button>Enter</button>
        </form>

        <hr />

        <h1>{this.state.message}</h1>
      </div>
    );
  }
}

export default Creator;