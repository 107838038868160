import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = 'pk.eyJ1IjoiY2VyZWFsbGFyY2VueSIsImEiOiJjamxweW4wNmoyYTFqM3dzMWVoc3h5Mjg1In0.HO2CBsYtWDrby-O1SuafEg';

class Map extends Component {
  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/dark-v9',
      interactive: false
    });

    this.map.on('load', () => {
      let features = [];

      this.props.cities.forEach(({ title, coordinates, color }) => {
        features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates
          },
          properties: {
            title
          }
        });

        var el = document.createElement('div');
        el.className = 'marker ' + color;

        new mapboxgl.Marker(el).setLngLat(coordinates).addTo(this.map);
      });

      this.map.addLayer({
        "id": "points",
        "type": "symbol",
        "source": {
          "type": "geojson",
          "data": {
            "type": "FeatureCollection",
            "features": features
          }
        }
      });

      const coordinates = [];

      features.forEach(({ geometry }) => {
        coordinates.push(geometry.coordinates);
      });

      const bounds = coordinates.reduce((bounds, coord) => {
        return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

      this.map.fitBounds(bounds, { padding: 80 });
    });
  }

  componentWillUnmount() {
    this.map.remove();
  }

  render() {
    const containerStyle = {
      position: 'relative',
      height: this.props.height,
      marginBottom: '20px'
    };

    const mapStyle = {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      borderRadius: '6px'
    };

    return (
      <div style={containerStyle}>
        <div style={mapStyle} ref={el => this.mapContainer = el} />
      </div>
    );
  }
}

export default Map;
