import React, { Component } from 'react';
import moment from 'moment';

import {
  Columns,
  Heading,
  Box,
  Container,
  Section,
  Button,
  Level,
  Card,
  Media,
  Image,
  Content,
  Tag,
  Message,
  Hero,
  Field,
  Control,
  Input
} from 'react-bulma-components';

import Map from './Map';

import FAKE_DATA from './fake-data';

import logo from '../logo.svg';

class ProofQuery extends Component {
  state = {
    artifactCreatorAddress: '',
    artifact: {},
    newOwner: '',
    newOwnerAddress: '',
    message: null
  };

  async componentDidMount() {
    this.getArtifactCreator();
    this.getProofInformation();
  }

  onTransferOwnership = async (event) => {
    event.preventDefault();

    const accounts = await this.props.web3.eth.getAccounts();

    const { newOwner, newOwnerAddress, artifactOwner, artifactOwnerAddress } = this.state;
    const owner = newOwner || artifactOwner;
    const ownerAddress = newOwnerAddress || artifactOwnerAddress;

    this.setState({ message: { text: 'Waiting on transaction success...', type: 'info' } });

    await this.props.origami.methods.transferOwnership(
      +this.props.match.params.proof,
      owner,
      ownerAddress
    ).send({
      from: accounts[0]
    });

    this.setState({
      message: { text: 'Artifact ownership has been transfered!', type: 'success' },
      newOwner: '',
      newOwnerAddress: ''
    });

    this.getProofInformation();

    setTimeout(() => {
      this.setState({ message: null });
    }, 5000);
  }

  getArtifactCreator = async () => {
    if(this.props.capable) {
      const artifactCreatorAddress = await this.props.origami.methods.artifactCreatorAddress().call();

      this.setState({ artifactCreatorAddress });
    }
  }

  getProofInformation = async () => {
    const proofId = +this.props.match.params.proof;

    if(this.props.capable) {
      const artifact = await this.props.origami.methods.artifacts(proofId).call();

      artifact.timeAndDateCompleted = moment.unix(artifact.timeAndDateCompleted).fromNow();
      artifact.details = JSON.parse(artifact.details);

      this.setState({ artifact });
    }
    else {
      let artifact;

      if (proofId <= 250) {
        artifact = FAKE_DATA[1];
      }
      else if (proofId > 250) {
        artifact = FAKE_DATA[2];
      }
      if (proofId == 472) {
        artifact.handMadeBy = 'Kristie Yung';
        artifact.presentedAt = 'Remote Location';
        artifact.artifactOwner = 'Kristie Yung';
      }
      if (proofId == 377) {
        artifact.handMadeBy = 'Patrick Cason';
        artifact.presentedAt = 'Remote Location';
        artifact.artifactOwner = 'Patrick Cason';
      }
      if (proofId == 389) {
        artifact.handMadeBy = 'Rima Patel';
        artifact.presentedAt = 'Remote Location';
        artifact.artifactOwner = 'Rima Patel';
      }
      if (proofId == 164) {
        artifact.handMadeBy = 'Aaron Suarez';
        artifact.presentedAt = 'Remote Location';
        artifact.artifactOwner = 'Aaron Suarez';
      }
      if (proofId == 432) {
        artifact.handMadeBy = 'Alex Chudik';
        artifact.presentedAt = 'Remote Location';
        artifact.artifactOwner = 'Alex Chudik';
      }
      if (proofId == 330) {
        artifact.handMadeBy = 'Christopher Paprocki';
        artifact.presentedAt = 'Remote Location';
        artifact.artifactOwner = 'Christopher Paprocki';
      }

      artifact.proof = proofId;
      artifact.details = JSON.parse(artifact.details);

      this.setState({ artifact });
    }
  }

  render() {
    const { artifactOwner, handMadeBy, ownerAddress, presentedAt, timeAndDateCompleted, details } = this.state.artifact;
    const { message, newOwner, newOwnerAddress } = this.state;

    const proofId = +this.props.match.params.proof;
    const { capable } = this.props;

    return (

      <div id="proof-query">
        {!capable && <Message color="danger" style={{ marginBottom: '0px' }}>
          <Message.Body>Unfortunately, your browser does not support live blockchain data - all data below is cached and out of date. We suggest you <a href="https://itunes.apple.com/us/app/tokenpocket/id1288636393?mt=8" target="_blank" rel="noopener noreferrer">download Token Pocket</a> and switch to the Ropsten test network to view live blockchain data.</Message.Body>
        </Message>}
        <Hero color="dark">
          <Hero.Body>
            <Level renderAs="nav">
              <Level.Item>
                <Image size={64} alt="Origami Artifacts" src={logo} />
              </Level.Item>
            </Level>
          </Hero.Body>
          {capable && <Hero.Footer style={{ paddingBottom: '40px' }}>
            <Level renderAs="nav">
              <Level.Item>
                <Content style={{ textAlign: 'center' }}>
                  <Heading renderAs="p" heading>Contract Address</Heading>
                  <Heading renderAs="p" subtitle>{this.props.origami._address}</Heading>
                </Content>
              </Level.Item>
              <Level.Item>
                <Content style={{ textAlign: 'center' }}>
                  <Heading renderAs="p" heading>Creator Address</Heading>
                  <Heading renderAs="p" subtitle>{this.state.artifactCreatorAddress}</Heading>
                </Content>
              </Level.Item>
            </Level>
          </Hero.Footer>}
        </Hero>
        <Section>
          {details && <Container>
            <Columns>
              <Columns.Column>
                {message && <Message color={message.type}>
                  <Message.Body>{message.text}</Message.Body>
                </Message>}
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column tablet={{ size: 12 }} desktop={{ size: 4 }}>
                <Card style={{ borderRadius: '6px' }}>
                  <Card.Content>
                    <Media style={{ marginBottom: '2rem' }}>
                      <Media.Item renderAs="figure" position="left">
                        <Image className="artifact-icon" size={64} alt="Artifact" src={logo} />
                      </Media.Item>
                      <Media.Item>
                        <Heading size={4}>Proof {proofId} of 500</Heading>
                        <Heading subtitle size={6}>
                          <strong>Owner: </strong>{artifactOwner}<br />
                          <span style={{ fontSize: '0.45rem' }}>{ownerAddress}</span>
                        </Heading>
                      </Media.Item>
                    </Media>
                    <Content>
                      {capable && <form onSubmit={this.onTransferOwnership}>
                        <Field>
                          <Control>
                            <Input placeholder="Type a name..."
                              value={newOwner}
                              onChange={event => this.setState({ newOwner: event.target.value })} />
                          </Control>
                        </Field>
                        <Field>
                          <Control>
                            <Input placeholder="Type an address..."
                              value={newOwnerAddress}
                              onChange={event => this.setState({ newOwnerAddress: event.target.value })} />
                          </Control>
                        </Field>

                        <Button color="info" size="medium" disabled={!newOwner} fullwidth onClick={this.onTransferOwnership}>Transfer Ownership</Button>
                        <br />
                      </form>}

                      <Heading renderAs="p" heading>Presented At</Heading>
                      <Heading renderAs="p" subtitle>{presentedAt}</Heading>

                      <Heading renderAs="p" heading>Completed</Heading>
                      <Heading renderAs="p" subtitle>{timeAndDateCompleted}</Heading>

                      <Heading renderAs="p" heading>Total Creation Time</Heading>
                      <Heading renderAs="p" subtitle>{details.hoursCompressed} hours</Heading>

                      <Heading renderAs="p" heading>Paper Used</Heading>
                      <Heading renderAs="p" subtitle>{details.piecesPaper} pieces</Heading>

                      <Heading renderAs="p" heading>Hand-made by</Heading>
                      <Heading renderAs="p" subtitle>{handMadeBy}</Heading>
                    </Content>
                  </Card.Content>
                </Card>
              </Columns.Column>
              <Columns.Column>
                <Box>
                  <Map height="540px"
                    cities={[
                      {
                        title: 'Inspiration',
                        city: details.ideaLocation,
                        coordinates: [-70.6693, -33.4489],
                        // TODO: Kevin, these will work when you get the other origami address working
                        // coordinates: details.ideaLocationCoords,
                        color: 'red'
                      },
                      {
                        title: 'Testing',
                        city: details.testLocation,
                        coordinates: [-77.0428, -12.0464],
                        // TODO: Kevin, these will work when you get the other origami address working
                        // coordinates: details.testLocationCoords,
                        color: 'yellow'
                      },
                      {
                        title: 'Manufacturing',
                        city: details.mfgLocation,
                        coordinates: [-75.5812, 6.2442],
                        // TODO: Kevin, these will work when you get the other origami address working
                        // coordinates: details.mfgLocationCoords,
                        color: 'green'
                      },
                      {
                        title: 'Paper Source',
                        city: details.paperSource,
                        coordinates: [12.9062, 43.3450],
                        // TODO: Kevin, these will work when you get the other origami address working
                        // coordinates: details.paperSourceCoords,
                        color: 'blue'
                      },
                      {
                        title: 'Box Source',
                        city: details.boxSource,
                        coordinates: [-71.0589, 42.3601],
                        // TODO: Kevin, these will work when you get the other origami address working
                        // coordinates: details.boxSourceCoords,
                        color: 'light'
                      }
                    ]} />
                  <Level renderAs="nav" style={{ padding: '20px 0px' }}>
                    <Level.Item className="has-text-centered has-text-left-desktop">
                      <div>
                        <Heading renderAs="p" heading>
                          <Tag color="danger">Inspiration</Tag>
                        </Heading>
                        <Heading renderAs="p">{details.ideaLocation}</Heading>
                      </div>
                    </Level.Item>
                    <Level.Item className="has-text-centered has-text-left-desktop">
                      <div>
                        <Heading renderAs="p" heading>
                          <Tag color="warning">Testing</Tag>
                        </Heading>
                        <Heading renderAs="p">{details.testLocation}</Heading>
                      </div>
                    </Level.Item>
                    <Level.Item className="has-text-centered has-text-left-desktop">
                      <div>
                        <Heading renderAs="p" heading>
                          <Tag color="success">Manufacturing</Tag>
                        </Heading>
                        <Heading renderAs="p">{details.mfgLocation}</Heading>
                      </div>
                    </Level.Item>
                    <Level.Item className="has-text-centered has-text-left-desktop">
                      <div>
                        <Heading renderAs="p" heading>
                          <Tag color="info">Paper Source</Tag>
                        </Heading>
                        <Heading renderAs="p">{details.paperSource}</Heading>
                      </div>
                    </Level.Item>
                    <Level.Item className="has-text-centered has-text-left-desktop">
                      <div>
                        <Heading renderAs="p" heading>
                          <Tag color="light">Box Source</Tag>
                        </Heading>
                        <Heading renderAs="p">{details.boxSource}</Heading>
                      </div>
                    </Level.Item>
                  </Level>
                </Box>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <div style={{ textAlign: 'center', padding: '40px 0px' }}>
                  <Heading renderAs="p" heading>Designed By</Heading>
                  <Heading renderAs="p">{details.designedAndManufacturedBy}</Heading>
                </div>
              </Columns.Column>
            </Columns>
          </Container>}
        </Section>
      </div>
    );
  }
}

export default ProofQuery;
